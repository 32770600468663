import React, { useEffect } from "react";
import "./about.css";
import Navbar from "../../components/Navbar/navbar";
import Footer from "../../components/footer/Footer";
import aboutHeroImage from "../../assets/about-hero-image.png";
import { useNavigate } from "react-router-dom";
import { EMPLOYEE__PROFILE } from "../../config/profileData";
import AOS from "aos";
import "aos/dist/aos.css";
import { Tilt } from 'react-tilt';

function About() {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <>
      <div className="about">
        <Navbar />
        <section className="about__section__one" data-aos="fade-up">
          <h3>About us</h3>
          <h1>
            Your trusted <b>partner in</b> software development
          </h1>
          <img alt="hero image" src={aboutHeroImage} />
        </section>
        <section className="about__section__two">
          <button>TEAM</button>
          <h1 data-aos="fade-down">Meet our creative team</h1>
          <p data-aos="fade-up">
            We bring ideas to life by combining years of experiences of our
            talented team.
          </p>
          <div className="about__section__two__staff">
            {EMPLOYEE__PROFILE.map((item, index) => {
              return (
                <Tilt
                  key={item.id}
                  options={{
                    max: 25,
                    scale: 1.05,
                    speed: 400,
                    transition: true
                  }}
                  className="about__section__two__staff__card"
                >
                  <div 
                    data-aos="flip-up" 
                    data-aos-delay={index * 100} 
                    className={`folder${index % 2 === 0 ? "" : " alt"}`}
                    onClick={() => navigate(`/profile/${item.id}`)}
                  >
                    <div className="folder-top"></div>
                    <div className="folder-body">
                      <img
                        src={item.image}
                        alt="Cut-out person"
                        className="cutout-image"
                      />
                    </div>
                  </div>
                  <h1>{item.name}</h1>
                  <p>{item.position}</p>
                </Tilt>
              );
            })}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default About;