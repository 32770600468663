import { useParams } from "react-router-dom";
import { MdDoubleArrow } from "react-icons/md";
import "./profile.css";
import { EMPLOYEE__PROFILE } from "../../config/profileData";
import Navbar from "../../components/Navbar/navbar";
import Footer from "../../components/footer/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function Profile() {
  const params = useParams();

  const profile = EMPLOYEE__PROFILE.find(
    (employee) => employee.id == params.id
  );

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <>
      <div className="profile__bg">
        <Navbar />
        <div className="profile__main">
          <h1 data-aos="fade-up">
            Team <MdDoubleArrow /> {profile.name}
          </h1>
          <div className="profile">
            <div className="profile__header" data-aos="fade-right">
              <img src={profile.image} alt={profile.name} />
            </div>
            <div className="profile__body" data-aos="fade-left">
              <div className="folder profile">
                <div className="folder-top"></div>
                <div className="folder-body profile">
                  <h3>Name:</h3>
                  <p>{profile.name}</p>
                  <h3>Position:</h3>
                  <p>{profile.position}</p>
                  <h3>Bio:</h3>
                  <p>{profile.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Profile;
