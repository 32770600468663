import React, { useEffect } from 'react';
import Navbar from '../../components/Navbar/navbar';
import './work.css';
import Footer from '../../components/footer/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Work() {

  const handleButtonClick = () => {
    window.open("https://brandforgex.com", "_blank");
  };

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <div className='work'>
      <div className="Navbar">
        <Navbar />
      </div>
      <div className="workContainers">
        <div className="workTextContainers">
          <div className="firstContainer" data-aos="fade-up">
            <p className='firstText'>WORKS</p>
          </div>
          <div className="secondContainer" data-aos="fade-up">
            <p className='secondText'>Featured Works</p>
          </div>
          <div className="thirdContainer" data-aos="fade-up">
            <p className='thirdText'>Find our latest works here</p>
          </div>
        </div>
        <div className="workCardContainers">
          <div className="workCards1" data-aos="fade-right">
            <div className="workCards1-top"></div>
            <div className="workCardContainerInside1">
              <span className='businessText'>Over 4000+ business available</span>
            </div>
            <h2>Brand Marketing</h2>
            <h1>Optimized Research</h1>
            <p>Our vision is to revolutionize the way brands and</p>
            <p>advertisers target reach</p>
            <button className="getStarted-button1" onClick={handleButtonClick}>Get Started</button>
            <div className="workCardContainerInside2">
              <span className='workText'>WORKS</span>
            </div>
            <div className="workCardContainerInside3">
              <span className='websiteText'>Website Design</span>
            </div>
          </div>
          <div className="workRows">
            <div className="workCards2" style={{ backgroundImage: `url('/images/social.svg')` }} data-aos="fade-up">
              <div className="workCardContainerInside4">
                <span className='socialText'>SOCIAL MEDIA</span>
              </div>
              <div className="workCardContainerInside5">
                <span className='mediaText'>Social Media Strategy for Restaurant</span>
              </div>
            </div>
            <div className="workCards2" style={{ backgroundImage: `url('/images/market.svg')` }} data-aos="fade-up">
              <div className="workCardContainerInside4">
                <span className='socialText'>MARKETING</span>
              </div>
              <div className="workCardContainerInside8">
                <span className='mediaText'>Branding a New Startup</span>
              </div>
            </div>
          </div>
          <div className="workCards3" style={{ backgroundImage: `url('/images/brand.svg')` }} data-aos="fade-up">
            <div className="workCardContainerInside6">
              <span className='socialText'>FINANCE</span>
            </div>
            <div className="workCardContainerInside7">
              <span className='mediaText'>Branding a Local Business</span>
            </div>
            <div className="workCards3-bottom"></div>
          </div>
        </div>
      </div>
      <div className="Footer">
        <Footer />
      </div>
    </div>
  );
}

export default Work;
