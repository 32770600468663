import React, { useState, useEffect } from 'react';
import Navbar from '../../components/Navbar/navbar';
import './pricing.css';
import Footer from '../../components/footer/Footer';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Pricing() {
    const [selectedPeriod, setSelectedPeriod] = useState('Monthly');
    const navigate = useNavigate();

    const handlePeriodChange = (period) => {
        setSelectedPeriod(period);
    };

    const handlePlanSelection = (plan, price) => {
        navigate(`/?plan=${plan}&price=${price}#geetek-form`);
    };

    // Define constants for prices
    const prices = {
        Monthly: {
            WebsiteDevelopment: { Basic: 200, Standard: 400, Premium: 700 },
            SoftwareDevelopment: { Basic: 300, Standard: 500, Premium: 800 },
            Branding: { Basic: 150, Standard: 300, Premium: 500 },
            DigitalMarketing: { Basic: 250, Standard: 450, Premium: 750 }
        },
        '6 Months': {
            WebsiteDevelopment: { Basic: 1100, Standard: 2200, Premium: 3900 },
            SoftwareDevelopment: { Basic: 1700, Standard: 2850, Premium: 4500 },
            Branding: { Basic: 850, Standard: 1700, Premium: 2800 },
            DigitalMarketing: { Basic: 1400, Standard: 2550, Premium: 4200 }
        },
        '1 Year': {
            WebsiteDevelopment: { Basic: 2000, Standard: 3900, Premium: 6600 },
            SoftwareDevelopment: { Basic: 3200, Standard: 5400, Premium: 8400 },
            Branding: { Basic: 1500, Standard: 2900, Premium: 4800 },
            DigitalMarketing: { Basic: 2700, Standard: 5000, Premium: 8400 }
        },
        '2 Years': {
            WebsiteDevelopment: { Basic: 3600, Standard: 7200, Premium: 12000 },
            SoftwareDevelopment: { Basic: 6000, Standard: 10000, Premium: 15600 },
            Branding: { Basic: 2800, Standard: 5400, Premium: 9000 },
            DigitalMarketing: { Basic: 5000, Standard: 9600, Premium: 16800 }
        }
    };

    // Define descriptions for each category and period
    const descriptions = {
        Monthly: {
            WebsiteDevelopment: [
                'UI/UX Design', 'Frontend Development', 'Backend Development',
                'E-commerce Integration', 'SEO Optimization', 'Content Management System (CMS)',
                'Responsive Design', 'Maintenance and Support'
            ],
            SoftwareDevelopment: [
                'Requirement Analysis', 'System Design', 'Application Development', 'Testing and QA',
                'Deployment', 'Integration Services', 'Maintenance and Support', 'Custom Software Solutions'
            ],
            Branding: [
                'Logo Design', 'Brand Identity Creation', 'Business Cards and Stationery Design',
                'Brand Guidelines', 'Packaging Design', 'Marketing Collateral', 'Social Media Branding',
                'Rebranding Services'
            ],
            DigitalMarketing: [
                'SEO (Search Engine Optimization)', 'SEM (Search Engine Marketing)', 'Social Media Marketing',
                'Content Marketing', 'Email Marketing', 'PPC (Pay-Per-Click) Advertising',
                'Analytics and Reporting', 'Reputation Management'
            ]
        },
        '6 Months': {
            WebsiteDevelopment: [
                '($183/Month)', 'Includes all Monthly Basic Plan',
                '($367/Month)', 'Includes all Monthly Standard Plan',
                '($650/Month)', 'Includes all Monthly Premium Plan'
            ],
            SoftwareDevelopment: [
                '($283/Month)', 'Includes all Monthly Basic Plan',
                '($475/Month)', 'Includes all Monthly Standard Plans',
                '($750/Month)', 'Includes all Monthly Premium Plans'
            ],
            Branding: [
                '($142/Month)', 'Includes all Monthly Basic Plan',
                '($283/Month)', 'Includes all Monthly Standard Plans',
                '($467/Month)', 'Includes all Monthly Premium Plans'
            ],
            DigitalMarketing: [
                '($233/Month)', 'Includes all Monthly Basic Plan',
                '($425/Month)', 'Includes all Monthly Standard Plans',
                '($700/Month)', 'Includes all Monthly Premium Plans'
            ]
        },
        '1 Year': {
            WebsiteDevelopment: [
                '($167/Month)', 'Includes all Monthly Basic Plan',
                '($325/Month)', 'Includes all Monthly Standard Plan',
                '($550/Month)', 'Includes all Monthly Premium Plan'
            ],
            SoftwareDevelopment: [
                '($267/Month)', 'Includes all Monthly Basic Plan',
                '($450/Month)', 'Includes all Monthly Standard Plans',
                '($700/Month)', 'Includes all Monthly Premium Plans'
            ],
            Branding: [
                '($125/Month)', 'Includes all Monthly Basic Plan',
                '($242/Month)', 'Includes all Monthly Standard Plans',
                '($400/Month)', 'Includes all Monthly Premium Plans'
            ],
            DigitalMarketing: [
                '($225/Month)', 'Includes all Monthly Basic Plan',
                '($417/Month)', 'Includes all Monthly Standard Plans',
                '($700/Month)', 'Includes all Monthly Premium Plans'
            ]
        },
        '2 Years': {
            WebsiteDevelopment: [
                '($150/Month)', 'Includes all Monthly Basic Plan',
                '($300/Month)', 'Includes all Monthly Standard Plan',
                '($500/Month)', 'Includes all Monthly Premium Plans'
            ],
            SoftwareDevelopment: [
                '($250/Month)', 'Includes all Monthly Basic Plan',
                '($417/Month)', 'Includes all Monthly Standard Plans',
                '($650/Month)', 'Includes all Monthly Premium Plans'
            ],
            Branding: [
                '($117/Month)', 'Includes all Monthly Basic Plan',
                '($225/Month)', 'Includes all Monthly Standard Plans',
                '($375/Month)', 'Includes all Monthly Premium Plans'
            ],
            DigitalMarketing: [
                '($208/Month)', 'Includes all Monthly Basic Plan',
                '($400/Month)', 'Includes all Monthly Standard Plans',
                '($700/Month)', 'Includes all Monthly Premium Plans'
            ]
        }
    };

// Function to render descriptions
const renderDescriptions = (category, planType) => {
    const selectedDescriptions = descriptions[selectedPeriod]?.[category];

    if (!selectedDescriptions || selectedDescriptions.length === 0) {
        return <li>No description available for this plan.</li>;
    }

    // Define how many descriptions to show per plan
    const descriptionsPerPlan = 2;

    // Determine the starting index based on the plan type
    const planIndex = {
        Basic: 0,
        Standard: 2,
        Premium: 4
    }[planType];

    // Slice the descriptions array to get the relevant descriptions
    const relevantDescriptions = selectedDescriptions.slice(planIndex, planIndex + descriptionsPerPlan);

    return relevantDescriptions.map((desc, index) => (
        <li key={index}>
            <img src="images/price-black-tick.svg" alt="tick" />{desc}
        </li>
    ));
};

useEffect(() => {
    AOS.init({
        duration: 800,
        easing: 'ease-in-out',
        once: true,
    });
}, []);

    return (
        <div className='pricing'>
        <div className="Navbar">
            <Navbar />
        </div>
        <img src="images/22.png" alt="semi" className='semiDesign' data-aos="fade-in" data-aos-delay="200" />
        <div className="pricingContainers">
            <div className="pricingTextContainers">
                <div className="firstContainer" data-aos="fade-up">
                    <p className='firstText'>PRICING</p>
                </div>
                <div className="secondContainer" data-aos="fade-up" data-aos-delay="100">
                    <p className='secondText'>Pricing Plans</p>
                </div>
                <div className="thirdContainer" data-aos="fade-up" data-aos-delay="200">
                    <p className='thirdText'>Check our easy and simple plans</p>
                </div>
                <div className="fourthContainer" data-aos="zoom-in" data-aos-delay="300">
                    <button className={`button ${selectedPeriod === 'Monthly' ? 'active' : ''} first-button`} onClick={() => handlePeriodChange('Monthly')}>Monthly</button>
                    <button className={`button ${selectedPeriod === '6 Months' ? 'active' : ''}`} onClick={() => handlePeriodChange('6 Months')}>6 Months</button>
                    <button className={`button ${selectedPeriod === '1 Year' ? 'active' : ''}`} onClick={() => handlePeriodChange('1 Year')}>1 Year</button>
                    <button className={`button ${selectedPeriod === '2 Years' ? 'active' : ''} last-button`} onClick={() => handlePeriodChange('2 Years')}>2 Years</button>
                </div>
                <div className="dropdown-container" data-aos="fade-up" data-aos-delay="400">
                    <select className="period-dropdown" value={selectedPeriod} onChange={(e) => handlePeriodChange(e.target.value)}>
                        <option value="Monthly">Monthly</option>
                        <option value="6 Months">6 Months</option>
                        <option value="1 Year">1 Year</option>
                        <option value="2 Years">2 Years</option>
                    </select>
                </div>
            </div>

            <div className="pricingCardContainers">
                {/* Website Development Pack Cards */}

                    <div className="pricingCards1" data-aos="zoom-in" data-aos-delay="500">
                        <div className="pricingCards1-top"></div>
                        <h2>Website Development</h2>
                        <p>Get unlimited services retainer</p>
                        <span className="price1">Basic Plan: ${prices[selectedPeriod]?.WebsiteDevelopment?.Basic || 'N/A'}</span>
                        <h3>Includes:</h3>
                        <ul>{renderDescriptions('WebsiteDevelopment', 'Basic')}</ul>
                        <span className="price1">Standard Plan: ${prices[selectedPeriod]?.WebsiteDevelopment?.Standard || 'N/A'}</span>
                        <h3>Includes:</h3>
                        <ul>{renderDescriptions('WebsiteDevelopment', 'Standard')}</ul>
                        <span className="price1">Premium Plan: ${prices[selectedPeriod]?.WebsiteDevelopment?.Premium || 'N/A'}</span>
                        <h3>Includes:</h3>
                        <ul>{renderDescriptions('WebsiteDevelopment', 'Premium')}</ul>
                        <button className="plan-button1" onClick={() => handlePlanSelection('Website Development Pack', prices[selectedPeriod]?.WebsiteDevelopment?.Basic || 0)}>Get This Plan</button>
                    </div>

                {/* Software Development Pack Cards */}
                    <div className="pricingCards2" data-aos="zoom-in" data-aos-delay="600">
                        <div className="pricingCards2-top"></div>
                        <h2>Software Development</h2>
                        <p>Get access to all of our services</p>
                        <span className="price2">Basic Plan: ${prices[selectedPeriod]?.SoftwareDevelopment?.Basic || 'N/A'}</span>
                        <h3>Includes:</h3>
                        <ul>{renderDescriptions('SoftwareDevelopment', 'Basic')}</ul>
                        <span className="price2">Standard Plan: ${prices[selectedPeriod]?.SoftwareDevelopment?.Standard || 'N/A'}</span>
                        <h3>Includes:</h3>
                        <ul>{renderDescriptions('SoftwareDevelopment', 'Standard')}</ul>
                        <span className="price2">Premium Plan: ${prices[selectedPeriod]?.SoftwareDevelopment?.Premium || 'N/A'}</span>
                        <h3>Includes:</h3>
                        <ul>{renderDescriptions('SoftwareDevelopment', 'Premium')}</ul>
                        <button className="plan-button2" onClick={() => handlePlanSelection('Software Development Pack', prices[selectedPeriod]?.SoftwareDevelopment?.Basic || 0)}>Get This Plan</button>
                    </div>

                {/* Branding Pack Cards */}
                    <div className="pricingCards2" data-aos="zoom-in" data-aos-delay="700">
                        <div className="pricingCards2-top"></div>
                        <h2>Branding</h2>
                        <p>Enhance your brand presence</p>
                        <span className="price2">Basic Plan: ${prices[selectedPeriod]?.Branding?.Basic || 'N/A'}</span>
                        <h3>Includes:</h3>
                        <ul>{renderDescriptions('Branding', 'Basic')}</ul>
                        <span className="price2">Standard Plan: ${prices[selectedPeriod]?.Branding?.Standard || 'N/A'}</span>
                        <h3>Includes:</h3>
                        <ul>{renderDescriptions('Branding', 'Standard')}</ul>
                        <span className="price2">Premium Plan: ${prices[selectedPeriod]?.Branding?.Premium || 'N/A'}</span>
                        <h3>Includes:</h3>
                        <ul>{renderDescriptions('Branding', 'Premium')}</ul>
                        <button className="plan-button2" onClick={() => handlePlanSelection('Branding Pack', prices[selectedPeriod]?.Branding?.Basic || 0)}>Get This Plan</button>
                    </div>

                {/* Digital Marketing Pack Cards */}
                    <div className="pricingCards2" data-aos="zoom-in" data-aos-delay="800">
                        <div className="pricingCards2-top"></div>
                        <h2>Digital Marketing</h2>
                        <p>Boost your online presence</p>
                        <span className="price2">Basic Plan: ${prices[selectedPeriod]?.DigitalMarketing?.Basic || 'N/A'}</span>
                        <h3>Includes:</h3>
                        <ul>{renderDescriptions('DigitalMarketing', 'Basic')}</ul>
                        <span className="price2">Standard Plan: ${prices[selectedPeriod]?.DigitalMarketing?.Standard || 'N/A'}</span>
                        <h3>Includes:</h3>
                        <ul>{renderDescriptions('DigitalMarketing', 'Standard')}</ul>
                        <span className="price2">Premium Plan: ${prices[selectedPeriod]?.DigitalMarketing?.Premium || 'N/A'}</span>
                        <h3>Includes:</h3>
                        <ul>{renderDescriptions('DigitalMarketing', 'Premium')}</ul>
                        <button className="plan-button2" onClick={() => handlePlanSelection('Digital Marketing Pack', prices[selectedPeriod]?.DigitalMarketing?.Basic || 0)}>Get This Plan</button>
                    </div>
            </div>
        </div>
        <Footer />
    </div>
    );
}

export default Pricing;
