import React, { useEffect, useCallback, useState } from "react";
import { MdKeyboardArrowRight, MdOutlineMessage } from "react-icons/md";
import { SlHome } from "react-icons/sl";
import { PiToolboxLight } from "react-icons/pi";
import { useLocation, useNavigate } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

import "./home.css";
import Home1 from "../../sections/home-1/home-1";
import Home2 from "../../sections/home-2/home-2";
import Home3 from "../../sections/home-3/home-3";
import Footer from "../../components/footer/Footer";
import getInTouch from "../../assets/home-get-in-touch.png";
import image1 from "../../assets/home-news-one.png";
import image2 from "../../assets/home-news-two.png";
import image3 from "../../assets/home-news-three.png";
import image4 from "../../assets/home-news-four.png";
import image5 from "../../assets/home-overview-one.png";
import image6 from "../../assets/home-overview-two.png";
import image7 from "../../assets/home-overview-three.png";
import image8 from "../../assets/home-overview-five.png";

function Home() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const plan = query.get("plan");
  const price = query.get("price");

  const [faq, setFaq] = useState({
    select1: true,
    select2: false,
    select3: false,
    select4: false,
    select5: false,
    select6: false,
  });

  const [formStatus, setFormStatus] = useState({
    submitting: false,
    submitted: false,
  });

  const handleFaqClick = useCallback((option) => {
    setFaq((prevFaq) => ({
      ...prevFaq,
      [option]: !prevFaq[option],
    }));
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    budget: price || "",
    message: plan
      ? `Hi, I would like to discuss more about the ${plan} plan.`
      : "",
  });

  useEffect(() => {
    if (plan || price) {
      const formElement = document.getElementById("geetek-form");
      if (formElement) {
        formElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [plan, price]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form inputs
    if (
      !formData.name ||
      !formData.phone ||
      !formData.email ||
      !formData.message
    ) {
      alert("Please fill out all required fields.");
      return;
    }

    try {
      setFormStatus({ ...formStatus, submitting: true });

      const response = await fetch(
        "https://grocepayment.testaws24.pro/send-geetek-emails",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: formData,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send email");
      }

      setFormStatus({ submitting: false, submitted: true });
      setFormData({
        name: "",
        phone: "",
        email: "",
        budget: "",
        message: "",
      });

      setTimeout(() => {
        setFormStatus({ ...formStatus, submitted: false });
      }, 5000);
    } catch (error) {
      console.error("Error sending email:", error);
      setFormStatus({ submitting: false, submitted: false });
      // Handle error scenario
    }
  };

  const handleBlogPress = (title, subtitle, image) => {
    navigate("/blog", {
      state: {
        title: title,
        subtitle: subtitle,
        image: image,
      },
    });
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className="home__container__main">
      <Home1 />
      {/* <Home2 /> */}
      <Home3 />
      <section className="home__section__four">
      <div className="home__section__four__start">
        <div
          className="home__section__four__start__one__folder"
          data-aos="fade-right"
        >
          <div className="home__section__four__start__one__folder__text">
            <button>OVERVIEW</button>
            <h1>Why choose us?</h1>
            <h3>Geetek provides the best solutions</h3>
          </div>
          <div className="home__section__four__start__one__folder-top"></div>
          <div className="home__section__four__start__one__folder-body">
            <div>
              <h1>97%</h1>
              <h2>Growth</h2>
              <h3>
                Our team of experts provides tailored software solutions to
                meet your marketing goals and fuel your business growth.
              </h3>
            </div>
            <img src={image8} alt="Growth illustration" />
          </div>
        </div>
        <div
          className="home__section__four__start__two"
          data-aos="fade-left"
        >
          <img src={image7} alt="Strict Deadline" />
          <button>Strict Deadline</button>
          <h3>
            We will keep the timelines of deliverables as strict as possible.
          </h3>
        </div>
      </div>
      <div className="home__section__four__end">
        <div
          className="home__section__four__end__one"
          data-aos="fade-up"
        >
          <img src={image5} alt="Strict Deadline" />
          <button>Strict Deadline</button>
          <h3>
            We will keep the timelines of deliverables as strict as possible.
          </h3>
        </div>
        <div
          className="home__section__four__end__two"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div>
            <button>Smart Experience</button>
            <h3>The Best UI and content for your business is a guarantee.</h3>
          </div>
          <img src={image6} alt="Smart Experience" />
        </div>
      </div>
    </section>
      <section className="home__section__five" data-aos="fade-up">
        <button>BLOG</button>
        <h1>News and Updates</h1>
        <div className="home__section__five__start" data-aos="fade-right">
          <img
            src={image1}
            alt="workers"
            className="home__section__five__start__img"
          />
          <div className="home__section__five__start__content">
            <button>UPDATE</button>
            <h1>The Ultimate Guide to Launching a Successful Startup</h1>
            <h3>
              Embarking on the entrepreneurial path to launch a startup is a
              thrilling adventure filled with opportunities and challenges. To
              navigate this journey successfully, it's essential to have a
              well-thought-out plan and a clear vision for your startup. In this
              blog post, we will explore the key steps and strategies to help
              you launch a successful startup and turn your innovative ideas
              into a thriving business.
            </h3>

            <button
              onClick={() =>
                handleBlogPress(
                  "The Ultimate Guide to Launching a Successful Startup",
                  `Embarking on the entrepreneurial path to launch a startup is a thrilling adventure filled with opportunities and challenges. To navigate this journey successfully, it's essential to have a well-thought-out plan and a clear vision for your startup. In this blog post, we will explore the key steps and strategies to help you launch a successful startup and turn your innovative ideas into a thriving business.<br/><br/>
              <strong>Define Your Vision and Mission:</strong><br/>
              Before diving into the nitty-gritty details of your startup, take the time to define your vision and mission. Clearly articulate what problem your startup aims to solve, who your target audience is, and what sets your product or service apart from the competition. Your vision and mission will serve as the guiding principles for your startup and help you stay focused on your goals.<br/><br/>
              <strong>Conduct Market Research:</strong><br/>
              Understanding your target market is crucial for the success of your startup. Conduct thorough market research to identify market trends, customer needs, and competitor analysis. By gaining insights into the market landscape, you can tailor your product or service to meet the demands of your target audience effectively.<br/><br/>
              <strong>Develop a Solid Business Plan:</strong><br/>
              A well-crafted business plan is the roadmap that will guide your startup towards success. Outline your business goals, revenue model, marketing strategy, and financial projections in your business plan. A solid business plan not only helps you stay organized but also attracts potential investors and partners.<br/><br/>
              <strong>Build a Strong Team:</strong><br/>
              Behind every successful startup is a dedicated and talented team. Surround yourself with individuals who share your passion and complement your skills. Assemble a team with diverse expertise in areas such as product development, marketing, sales, and finance to ensure that all aspects of your startup are covered.<br/><br/>
              <strong>Create a Minimum Viable Product (MVP):</strong><br/>
              Instead of waiting to perfect your product, consider launching a Minimum Viable Product (MVP) to gather feedback from early adopters. An MVP allows you to test your product in the market, iterate based on user feedback, and validate your startup idea before investing significant resources.<br/><br/>
              <strong>Focus on Marketing and Branding:</strong><br/>
              Effective marketing and branding are essential for creating awareness and generating interest in your startup. Develop a strong brand identity, establish an online presence through social media and digital marketing, and engage with your target audience to build a loyal customer base.<br/><br/>
              <strong>Seek Funding and Partnerships:</strong><br/>
              Securing funding is a critical step in scaling your startup. Explore different funding options such as bootstrapping, angel investors, venture capital, or crowdfunding to fuel the growth of your startup. Additionally, consider forming strategic partnerships with other businesses or organizations that can help accelerate your startup's growth.<br/><br/>
              Launching a successful startup requires a combination of passion, perseverance, and strategic planning. By following these key steps and strategies, you can lay a solid foundation for your startup and increase your chances of achieving long-term success in the competitive business landscape. Remember, the journey of entrepreneurship is filled with ups and downs, but with determination and a clear vision, you can turn your startup dreams into reality.`,
                  image1
                )
              }
            >
              Read More
            </button>
          </div>
        </div>
        <div className="home__section__five__end" data-aos="fade-left">
          <div className="home__section__five__end__block">
            <img
              src={image2}
              alt="workers"
              className="home__section__five__end__block__img"
            />
            <button>STUDIO</button>

            <h1>
              The Ultimate Guide to Website Maintenance: Keeping Your Online
              Presence Sharp
            </h1>
            <h2>
              In the digital age, a website is often the first point of contact
              between a business and its customers. To ensure your online
              presence remains strong, regular website maintenance is key.
            </h2>
            <h3
              onClick={() =>
                handleBlogPress(
                  "The Ultimate Guide to Website Maintenance: Keeping Your Online Presence Sharp",
                  `In the digital age, a website is often the first point of contact between a business and its customers. To ensure your online presence remains strong, regular website maintenance is key.<br/><br/>
              <strong>Regular Backups:</strong><br/>
              Backing up your website regularly is like having insurance for your online presence. In case of any unexpected issues like data loss or hacking, backups will be your lifesaver. Set up automated backups or do manual backups periodically to secure your website's data.<br/><br/>
              <strong>Software Updates:</strong><br/>
              Just like your phone or computer, website software needs updates to stay secure and perform well. Keep your content management system (CMS), plugins, themes, and other software up to date to patch vulnerabilities and ensure compatibility with the latest technologies.<br/><br/>
              <strong>Security Checks:</strong><br/>
              Protect your website from cyber threats by implementing security measures. Use SSL certificates for data encryption, install security plugins, set strong passwords, and regularly scan for malware. Stay vigilant against potential security breaches to safeguard your website and user data.<br/><br/>
              <strong>Content Management:</strong><br/>
              Fresh and engaging content is essential to attract and retain visitors. Regularly update your content, remove outdated information, check for broken links, and optimize images for faster loading times. Consistent content updates not only enhance user experience but also improve search engine rankings.<br/><br/>
              <strong>Performance Optimization:</strong><br/>
              A fast-loading website is crucial for user satisfaction and SEO. Optimize your website's performance by compressing images, minifying CSS and JavaScript files, enabling caching, and using a content delivery network (CDN) to reduce loading times. Regularly test your website's speed and make necessary adjustments.<br/><br/>
              <strong>Mobile Responsiveness:</strong><br/>
              With the increasing use of mobile devices, ensure your website is responsive and displays correctly on various screen sizes. Test your website on different devices and browsers to provide a seamless user experience across all platforms.<br/><br/>
              Maintaining a website requires consistent effort and attention to detail, but the benefits of a well-maintained site are worth it. By following these essential steps, you can keep your website secure, up-to-date, and user-friendly, ensuring a strong online presence for your business or personal brand.<br/><br/>
              I hope this blog post helps you understand the importance of website maintenance and provides valuable insights on how to keep your website in top condition!`,
                  image2
                )
              }
            >
              READ MORE
            </h3>
          </div>
          <div className="home__section__five__end__block">
            <img
              src={image3}
              alt="workers"
              className="home__section__five__end__block__img"
            />
            <button>DESIGN</button>

            <h1>
              10 Effective Strategies for Boosting Your Website's SEO Ranking
            </h1>
            <h2>
              Boosting your website's SEO ranking is crucial for increasing
              visibility and attracting more organic traffic. Having a strong
              SEO strategy is key to standing out online.
            </h2>
            <h3
              onClick={() =>
                handleBlogPress(
                  "10 Effective Strategies for Boosting Your Website's SEO Ranking",
                  `Boosting your website's SEO ranking is crucial for increasing visibility and attracting more organic traffic. Having a strong SEO strategy is key to standing out online. By implementing the right techniques, you can improve your website's visibility and attract more visitors. Here are 10 effective strategies to boost your website's SEO ranking.<br/><br/>
              <strong>1. Conduct Keyword Research:</strong><br/>
              Start by identifying relevant keywords that your target audience is searching for. Use tools like Google Keyword Planner or SEMrush to find high-volume, low-competition keywords to incorporate into your content.<br/><br/>
              <strong>2. Optimize On-Page Elements:</strong><br/>
              Ensure your title tags, meta descriptions, headings, and content include your target keywords. Optimize your URLs and images with descriptive keywords to improve search engine visibility.<br/><br/>
              <strong>3. Create High-Quality Content:</strong><br/>
              Produce engaging, informative, and original content that provides value to your audience. Focus on creating long-form content that answers questions and solves problems for users.<br/><br/>
              <strong>4. Improve Website Speed:</strong><br/>
              Optimize your website's loading speed to enhance user experience and SEO ranking. Compress images, minify CSS and JavaScript, and leverage browser caching to speed up your site.<br/><br/>
              <strong>5. Mobile Optimization:</strong><br/>
              With the increasing use of mobile devices, ensure your website is mobile-friendly. Use responsive design, optimize for mobile speed, and prioritize user experience on smartphones and tablets.<br/><br/>
              <strong>6. Build Quality Backlinks:</strong><br/>
              Earn backlinks from reputable and relevant websites to boost your site's authority. Focus on quality over quantity and aim for natural backlink growth through guest posting, partnerships, and content promotion.<br/><br/>
              <strong>7. Utilize Local SEO:</strong><br/>
              For businesses targeting local customers, optimize your website for local search. Create a Google My Business profile, include local keywords, and encourage customer reviews to improve local SEO rankings.<br/><br/>
              <strong>8. Enhance User Experience:</strong><br/>
              Provide a seamless and intuitive user experience on your website. Improve navigation, enhance readability, and optimize for accessibility to keep visitors engaged and lower bounce rates.<br/><br/>
              <strong>9. Monitor and Analyze Performance:</strong><br/>
              Regularly track your website's SEO performance using tools like Google Analytics and Google Search Console. Analyze key metrics, identify areas for improvement, and adjust your strategy accordingly.<br/><br/>
              <strong>10. Stay Updated with SEO Trends:</strong><br/>
              SEO is constantly evolving, so stay informed about the latest trends and algorithm updates. Follow industry blogs, attend webinars, and adapt your strategy to stay ahead in the competitive digital landscape.<br/><br/>
              By implementing these 10 effective strategies for boosting your website's SEO ranking, you can enhance your online visibility, attract more organic traffic, and achieve long-term success in the digital realm. Stay consistent, monitor your progress, and adapt your strategy to stay ahead of the competition.`,
                  image3
                )
              }
            >
              READ MORE
            </h3>
          </div>
          <div className="home__section__five__end__block">
            <img
              src={image4}
              alt="workers"
              className="home__section__five__end__block__img"
            />
            <button>DESIGN</button>
            <h1>The Dos and Don'ts of Social Media Marketing</h1>
            <h2>
              Social media marketing is a powerful tool for businesses to
              connect with their audience and grow their brand. It has
              revolutionized the way businesses interact with their customers.
            </h2>
            <h3
              onClick={() =>
                handleBlogPress(
                  "The Dos and Don'ts of Social Media Marketing",
                  `Social media marketing is a powerful tool for businesses to connect with their audience and grow their brand. It has revolutionized the way businesses interact with their customers. To make the most of this platform, it's essential to understand the dos and don'ts of social media marketing. Here are some key tips to help you navigate the world of social media effectively.<br/><br/>
              <strong>The Dos:</strong><br/><br/>
              <strong>Define Your Goals:</strong><br/>
              Clearly define your social media marketing goals. Whether it's increasing brand awareness, driving website traffic, or boosting sales, having specific objectives will guide your strategy.<br/><br/>
              <strong>Know Your Audience:</strong><br/>
              Understand your target audience's preferences, behaviors, and interests. Tailor your content to resonate with your audience and engage them effectively.<br/><br/>
              <strong>Create Quality Content:</strong><br/>
              Produce high-quality, relevant, and engaging content that adds value to your audience. Use a mix of visuals, videos, and interactive posts to keep your followers interested.<br/><br/>
              <strong>Engage and Interact:</strong><br/>
              Engage with your audience by responding to comments, messages, and mentions promptly. Foster two-way communication by asking questions, running polls, and encouraging user-generated content.<br/><br/>
              <strong>Analyze and Optimize:</strong><br/>
              Regularly analyze your social media performance using analytics tools. Monitor key metrics, such as engagement rates and conversion rates, and optimize your strategy based on data-driven insights.<br/><br/>
              <strong>The Don'ts:</strong><br/><br/>
              <strong>Don't Over-Promote:</strong><br/>
              Avoid bombarding your followers with promotional content. Strike a balance between promotional posts and valuable, informative content to maintain audience interest.<br/><br/>
              <strong>Don't Ignore Negative Feedback:</strong><br/>
              Address negative feedback or comments promptly and professionally. Use criticism as an opportunity to improve your products or services and showcase your commitment to customer satisfaction.<br/><br/>
              <strong>Don't Neglect Consistency:</strong><br/>
              Maintain a consistent posting schedule to keep your audience engaged. Inconsistency can lead to a drop in followers and decreased visibility on social media platforms.<br/><br/>
              <strong>Don't Buy Followers or Engagement:</strong><br/>
              Avoid purchasing followers or engagement as it can harm your credibility and authenticity. Focus on organic growth and building a genuine community of followers who are genuinely interested in your brand.<br/><br/>
              <strong>Don't Underestimate the Power of Visuals:</strong><br/>
              Visual content is key on social media. Don't underestimate the impact of high-quality images, videos, and graphics in capturing your audience's attention and conveying your brand message effectively.<br/><br/>
              By following these dos and don'ts of social media marketing, you can create a successful and engaging social media presence for your business. Remember to stay authentic, engage with your audience, and adapt your strategy based on data and feedback to achieve your marketing goals effectively.`,
                  image4
                )
              }
            >
              READ MORE
            </h3>
          </div>
        </div>
        <div className="home__section__five__end__part2" data-aos="fade-up">
          <div className="home__section__five__end__block">
            <img
              src={image2}
              alt="workers"
              className="home__section__five__end__block__img"
            />
            <button>STUDIO</button>
            <h1>
              Safeguarding Your Digital World: Essential Cybersecurity Tips
            </h1>
            <h2>
              In a world where our lives are increasingly intertwined with
              technology, protecting our digital assets and personal information
              is paramount. Cybersecurity plays a vital role in safeguarding
              against online threats.
            </h2>
            <h3
              onClick={() =>
                handleBlogPress(
                  "Safeguarding Your Digital World: Essential Cybersecurity Tips",
                  `In a world where our lives are increasingly intertwined with technology, protecting our digital assets and personal information is paramount. Cybersecurity plays a vital role in safeguarding against online threats. Here are some essential cybersecurity tips to help you stay safe in the digital realm:<br/><br/>
              <strong>Strong Passwords:</strong><br/>
              Use complex and unique passwords for each of your accounts. Consider using a mix of letters, numbers, and special characters. Avoid using easily guessable information like birthdays or pet names and also use a password manager to securely store and manage your passwords.<br/><br/>
              <strong>Enable Two-Factor Authentication (2FA):</strong><br/>
              Add an extra layer of security by enabling 2FA wherever possible. This ensures that even if your password is compromised, an additional verification step is required for access.<br/><br/>
              <strong>Keep Software Updated:</strong><br/>
              Regularly update your operating system, software, and apps to patch security vulnerabilities and protect against potential exploits.<br/><br/>
              <strong>Beware of Phishing Attacks:</strong><br/>
              Be cautious of unsolicited emails, messages, or links that request personal information. Verify the source before clicking on any links or providing sensitive data.<br/><br/>
              <strong>Secure Your Network:</strong><br/>
              Use strong encryption protocols on your home Wi-Fi network and consider setting up a guest network for visitors. Change default router passwords and regularly update firmware.<br/><br/>
              <strong>Backup Your Data:</strong><br/>
              Regularly backup your important files and data to an external hard drive or cloud storage. In case of a cyber incident, having backups ensures you can recover your information.<br/><br/>
              <strong>Use Secure Connections:</strong><br/>
              Avoid connecting to public Wi-Fi networks for sensitive transactions. Use a virtual private network (VPN) for secure and encrypted internet connections.<br/><br/>
              <strong>Educate Yourself:</strong><br/>
              Stay informed about the latest cybersecurity threats and best practices. Attend webinars, read cybersecurity blogs, and educate yourself on how to protect your digital assets.<br/><br/>
              <strong>Monitor Your Accounts:</strong><br/>
              Regularly review your bank statements, credit reports, and online accounts for any suspicious activity. Report any unauthorized transactions immediately.<br/><br/>
              <strong>Secure Your Devices:</strong><br/>
              Install reputable antivirus software on your devices and keep them updated. Consider enabling device encryption for an added layer of security.<br/><br/>
              By following these cybersecurity tips, you can significantly reduce the risk of falling victim to cyber threats and protect your digital presence. Stay vigilant, stay informed, and stay safe in the digital world!`,
                  image2
                )
              }
            >
              READ MORE
            </h3>
          </div>
          <div className="home__section__five__end__block">
            <img
              src={image3}
              alt="workers"
              className="home__section__five__end__block__img"
            />
            <button>DESIGN</button>
            <h1>THREE INTERESTING FACTS ABOUT MOBILE APPS</h1>
            <h2>
              In today's digitally-driven world, the ubiquitous presence of
              smartphones has transformed the way we access information and
              engage with content.
            </h2>
            <h3
              onClick={() =>
                handleBlogPress(
                  "THREE INTERESTING FACTS ABOUT MOBILE APPS",
                  `In today's digitally-driven world, the ubiquitous presence of smartphones has transformed the way we access information and engage with content. Mobile applications, commonly known as apps, have played a pivotal role in this revolution, offering a myriad of benefits to both businesses and users. Let's delve into three interesting facts about mobile apps in our daily lives that you probably didn't know!<br/><br/>
              <strong>Almost 100% of Screen Time is spent on apps:</strong><br/>
              In the contemporary digital landscape, a profound shift in user behavior has emerged, underscoring the omnipresence and influence of mobile applications. Almost 100% of screen time is now dedicated to navigating the diverse array of apps available. These applications have become integral components of our daily routines, seamlessly integrating into various aspects of our lives. From social media platforms facilitating communication and connection to productivity apps streamlining work processes, and entertainment apps providing a plethora of content, mobile apps have become the primary interface through which individuals engage with their devices. This dominance is not limited to any particular demographic; rather, it spans across age groups and geographical boundaries.<br/><br/>
              The ubiquitous nature of app usage reflects a fundamental transformation in how we access information, communicate with others, and entertain ourselves. As a result, the significance of traditional web browsing has diminished, giving way to the convenience and efficiency offered by purpose-built apps. Whether tracking health and fitness, managing finances, or indulging in gaming experiences, users increasingly turn to apps for tailored and seamless interactions.<br/><br/>
              This paradigm shift is not merely a fleeting trend but signifies a deep-rooted transformation in the way society navigates the digital landscape. It prompts businesses, developers, and content creators to adapt and innovate within the app ecosystem to meet the evolving needs and preferences of users. In essence, the near-complete migration of screen time to apps underscores their role as the primary gateway to our digital experiences, shaping the contours of our daily lives in unprecedented ways.<br/><br/>
              <strong>Android has almost 1.5 times apps than the App Store:</strong><br/>
              The Google Play Store has about 1.5 times more apps than the Apple App Store. This is because Android's system is more open, letting a wider range of developers create and publish apps. The actual numbers may change over time as new apps are added and old ones are removed. To get the latest and most accurate info, it's best to check the current counts on both platforms. But remember, having more apps doesn't always mean better quality or a better user experience.<br/><br/>
              This diversity in the Android platform allows for a greater variety of apps catering to different interests and needs. However, it's crucial to recognize that the exact ratio between the two app stores may evolve over time due to the continuous influx of new applications and the removal of outdated or less relevant ones. For the latest and most precise information regarding the respective app counts on both platforms, it is advisable to check the current statistics directly from Google Play Store and Apple App Store sources. Importantly, while the quantity of apps may be substantial, it's essential to acknowledge that the sheer number doesn't always directly correlate with the quality or overall user experience. The user should consider various factors such as app functionality, design, and reviews when assessing the value of the applications available on each platform.<br/><br/>
              <strong>Half of the Applications in the App Store have never been downloaded:</strong><br/>
              The phenomenon of approximately half of the applications in the App Store having never been downloaded underscores the challenges developers face in achieving visibility and user engagement. Despite the vast array of apps available, a significant portion struggles to capture the attention of users, leading to their virtual obscurity.<br/><br/>
              This stark reality sheds light on the importance of effective marketing, discoverability, and user experience in the highly competitive app landscape. Developers not only need to create functional and appealing apps but also employ strategies to enhance their visibility among the myriad options available to users. It's worth noting that this statistic reflects the evolving dynamics of user preferences, app saturation, and the constant influx of new applications. For users, it highlights the importance of making informed choices, relying on reviews, and exploring app recommendations to discover hidden gems amidst the vast sea of available applications.<br/><br/>
              Did you also know that…<br/>
              The term "app" was named the Word of the Year by the American Dialect Society in 2010.<br/>
              The first mobile app was created in 1997 for the Nokia 6110, allowing users to play the game "Snake."<br/>
              There are over 3.48 million apps available for download on the Google Play Store, and over 2.22 million on the Apple App Store.<br/>
              Angry Birds, a popular mobile game, took only three weeks to develop but became a global phenomenon.<br/>
              The Apple App Store was launched in 2008 with just 500 apps; now, it hosts millions.`,
                  image2
                )
              }
            >
              READ MORE
            </h3>
          </div>
        </div>
      </section>
      <section className="home__section__six" data-aos="fade-up">
      <div className="home__section__six__start" data-aos="fade-right">
        <div className="home__section__six__start__profile">
          <img src={getInTouch} alt="get in touch profile" />
        </div>
        <h1>Get in touch with us!</h1>
        <p>We are waiting for your message</p>
        <span className="home__section__six__start__span" data-aos="zoom-in" data-aos-delay="100">
          <SlHome className="home__section__six__start__span__icon" />
          <div>
            <h3>Address</h3>
            <p>London, United Kingdom</p>
          </div>
        </span>
        <span className="home__section__six__start__span" data-aos="zoom-in" data-aos-delay="200">
          <PiToolboxLight className="home__section__six__start__span__icon" />
          <div>
            <h3>Phone</h3>
            <p>+44 7753 140131 / +234 818 446 1131</p>
          </div>
        </span>
        <span className="home__section__six__start__span" data-aos="zoom-in" data-aos-delay="300">
          <MdOutlineMessage className="home__section__six__start__span__icon" />
          <div>
            <h3>Email</h3>
            <p>info@geetekglobal.com</p>
          </div>
        </span>
      </div>
      <div className="home__section__six__end" data-aos="fade-left">
        <form onSubmit={handleSubmit} id="geetek-form">
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            disabled={formStatus.submitting}
            data-aos="slide-up"
            data-aos-delay="400"
          />
          <input
            type="number"
            name="phone"
            placeholder="Phone"
            value={formData.phone}
            onChange={handleChange}
            disabled={formStatus.submitting}
            data-aos="slide-up"
            data-aos-delay="500"
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            disabled={formStatus.submitting}
            data-aos="slide-up"
            data-aos-delay="600"
          />
          <input
            type="text"
            name="budget"
            placeholder="Budget"
            value={formData.budget}
            onChange={handleChange}
            disabled={formStatus.submitting}
            data-aos="slide-up"
            data-aos-delay="700"
          />
          <textarea
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
            disabled={formStatus.submitting}
            data-aos="slide-up"
            data-aos-delay="800"
          ></textarea>
          <button type="submit" disabled={formStatus.submitting} data-aos="fade-up" data-aos-delay="900">
            {formStatus.submitting ? "Submitting..." : "Submit Now"}
          </button>
          {formStatus.submitted && (
            <div className="success-message" data-aos="fade-in">Email sent successfully!</div>
          )}
        </form>
      </div>
    </section>
      <section className="home__section__seven" data-aos="fade-up">
        <h1 data-aos="fade-down">Frequently Asked Question</h1>
        <div
          className={`home__section__seven__faq${
            faq.select1 ? "" : " notActive"
          }`}
        >
          <div
            className="home__section__seven__faq__ques"
            onClick={() => handleFaqClick("select1")}
          >
            <p>What products and services does your company offer?</p>
            <MdKeyboardArrowRight
              className={
                faq.select1
                  ? "home__section__seven__faq__icon clicked"
                  : "home__section__seven__faq__icon"
              }
            />
          </div>
          <div
            className={
              faq.select1
                ? "home__section__seven__faq__ans clicked"
                : "home__section__seven__faq__ans"
            }
          >
            <p>
              Geetek Global offers a range of services, including software
              solutions, custom web and mobile app development, digital
              marketing and business consulting services. We create tailored
              solutions for businesses looking to establish a strong online
              presence or enhance their mobile capabilities. Clients can explore
              our portfolio of past projects on our website to see samples of
              our work.
            </p>
          </div>
        </div>
        <div
          className={`home__section__seven__faq${
            faq.select2 ? "" : " notActive"
          }`}
        >
          <div
            className="home__section__seven__faq__ques"
            onClick={() => handleFaqClick("select2")}
          >
            <p>How can clients get technical support?</p>
            <MdKeyboardArrowRight
              className={
                faq.select2
                  ? "home__section__seven__faq__icon clicked"
                  : "home__section__seven__faq__icon"
              }
            />
          </div>
          <div
            className={
              faq.select2
                ? "home__section__seven__faq__ans clicked"
                : "home__section__seven__faq__ans"
            }
          >
            <p>
              Customers can get technical support by contacting our customer
              support team through email, phone, or live chat on our website.
              For common issues, we have detailed troubleshooting guides
              available on our support page. Our customer support team is
              available to assist with any technical queries.
            </p>
          </div>
        </div>
        <div
          className={`home__section__seven__faq${
            faq.select3 ? "" : " notActive"
          }`}
        >
          <div
            className="home__section__seven__faq__ques"
            onClick={() => handleFaqClick("select3")}
          >
            <p>How can clients pay for products and services?</p>
            <MdKeyboardArrowRight
              className={
                faq.select3
                  ? "home__section__seven__faq__icon clicked"
                  : "home__section__seven__faq__icon"
              }
            />
          </div>
          <div
            className={
              faq.select3
                ? "home__section__seven__faq__ans clicked"
                : "home__section__seven__faq__ans"
            }
          >
            <p>
              We offer transparent billing practices for all our services.
              Clients can choose from different service packages based on the
              scope of their project, and pay in three milestone payments, or
              fixed project fees. Our billing process is outlined clearly in our
              service agreements to ensure clarity for our clients.
            </p>
          </div>
        </div>
        <div
          className={`home__section__seven__faq${
            faq.select4 ? "" : " notActive"
          }`}
        >
          <div
            className="home__section__seven__faq__ques"
            onClick={() => handleFaqClick("select4")}
          >
            <p>How long does it take to complete a project?</p>
            <MdKeyboardArrowRight
              className={
                faq.select4
                  ? "home__section__seven__faq__icon clicked"
                  : "home__section__seven__faq__icon"
              }
            />
          </div>
          <div
            className={
              faq.select4
                ? "home__section__seven__faq__ans clicked"
                : "home__section__seven__faq__ans"
            }
          >
            <p>
              Our standard delivery time for website and mobile app development
              is 4 to 6 weeks. This time frame allows for a comprehensive
              development process, including design, implementation, testing,
              and revisions, ensuring a high-quality end product for our
              clients. However, the time frame differs with the projects.
            </p>
          </div>
        </div>
        <div
          className={`home__section__seven__faq${
            faq.select5 ? "" : " notActive"
          }`}
        >
          <div
            className="home__section__seven__faq__ques"
            onClick={() => handleFaqClick("select5")}
          >
            <p>How is the client's data protected?</p>
            <MdKeyboardArrowRight
              className={
                faq.select5
                  ? "home__section__seven__faq__icon clicked"
                  : "home__section__seven__faq__icon"
              }
            />
          </div>
          <div
            className={
              faq.select5
                ? "home__section__seven__faq__ans clicked"
                : "home__section__seven__faq__ans"
            }
          >
            <p>
              Client data security is paramount and always considered in any of
              our service development processes. We adhere to strict security
              protocols to safeguard sensitive information and ensure client
              confidentiality. Our development team follows industry best
              practices to protect client data from unauthorized access or
              breaches.
            </p>
          </div>
        </div>
        <div
          className={`home__section__seven__faq${
            faq.select6 ? "" : " notActive"
          }`}
        >
          <div
            className="home__section__seven__faq__ques"
            onClick={() => handleFaqClick("select6")}
          >
            <p>What is the company's history?</p>
            <MdKeyboardArrowRight
              className={
                faq.select6
                  ? "home__section__seven__faq__icon clicked"
                  : "home__section__seven__faq__icon"
              }
            />
          </div>
          <div
            className={
              faq.select6
                ? "home__section__seven__faq__ans clicked"
                : "home__section__seven__faq__ans"
            }
          >
            <p>
              Our company has a proven track record of delivering innovative
              tech solutions to a diverse range of clients. We prioritize client
              satisfaction and collaboration throughout the development process,
              ensuring that each project meets the unique needs and goals of our
              clients. Clients can connect with us through our website to learn
              more about our services and discuss their project requirements.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Home;
